



.ReactTags__tag {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px 6px;
  font-size: 13px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
}

.ReactTags__remove {
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
}

.ReactTags__tagInput {
  border: none;
  outline: none;
  padding: 3px;
  
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* .ReactTags__tagInput::placeholder {
  color: #aaa;
  
} */

.ReactTags__tagInputField{
  border:none;
  width:100%;
  outline:none;
  padding:5px
}